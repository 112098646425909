//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BienPhotosGallery',
  props: {
    photos: Array
  },
  data: function () {
    return {
      currentIndex: 0
    }
  },
  methods: {
    incrementIndex () {
      this.currentIndex += 1
      if (this.currentIndex >= this.photos.length) this.currentIndex = 0
    },
    decrementIndex () {
      this.currentIndex -= 1
      if (this.currentIndex < 0) this.currentIndex = this.photos.length - 1
    },
    setIndex (index) {
      this.currentIndex = index
    }
  }
}
